<template>
  <div class="digest_item">
    <div class="title_box">
      {{item.title}}
    </div>
    <div class="content_box">
      <div class="content">
        {{item.content}}
      </div>
    </div>
    <div class="info_box">
      <div class="book_info">
        <span v-if="item.bookName" class="book_name">《{{item.bookName}}》</span>
        <span v-if="item.bookAuthor" class="book_author">-{{item.bookAuthor}}</span>
      </div>
      <div class="editor_box">
        <span class="date">
          {{editDate}}
        </span>
        <span class="editor_name">
          {{item.editor}}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'digestListCard',
    data() {
      return {

      }
    },
    computed: {
      editDate() {
        return this.item.editTime.split(' ')[0]
      }
    },
    props: {
      item: {
        type: Object,
        default() {
          return {}
        }
      }
    },
    methods: {

    }
  }
</script>

<style lang="scss" scoped>
  @import 'index.scss'
</style>