<template>
  <div class="digest">
    <!-- <common-nav /> -->
    <div class="wrap golden">
      山脚太拥挤，我们高处见。
    </div>
    <div class="wrap content">
      <div class="type_list_box">
        <div class="type" :style="{background: bookType === index ? 'rgba(102, 153, 204, 1)' : 'rgba(0,0,0,.4)'}" v-for="(item, index) in bookTypes" :key="index" @click="changType(index)">
          {{item}}
        </div>
      </div>
      <div class="book_list_box">
        <div class="book_list">
          <!-- <img class="book" src="" alt="暂无图片"> -->
          <div class="book_box" v-for="(item, index) in bookList" :key="index" @click="bookDigest(item.bookName)">
            <img class="book" :src="item.logoUrl" alt="">
            <div v-if="item.bookName || item.bookAuthor" class="book_info">
              <div v-if="item.bookName" class="book_info_name">《{{item.bookName}}》</div>
              <div v-if="item.bookAuthor" class="book_info_author">{{item.bookAuthor}}</div>
            </div>
          </div>
        </div>
        <div class="page_box">
          <div v-show="pageNum > 0" class="prev_page" @click="prevPage">
            <i class="iconfont iconpageprev" />
          </div>
          <div class="page_content">
            <span class="page_num" :class="{selected: pageNum === item - 1}" v-for="(item, index) in bookPage" :key="index" @click="toPage(item)">
              {{item}}
            </span>
          </div>
          <div v-show="bookPage > pageNum+1" class="next_page" @click="nextPage">
            <i class="iconfont iconfenyexiayiye" />
          </div>
        </div>
      </div>
      <div class="digest_list_box">
        <div class="digest_list">
          <div class="digest" v-for="(item, index) in digestList" :key="index" @click="digestInfo(item)">
            <digest-list-card :item="item" />
          </div>
        </div>
        <div v-if="userName == 'zhouyichang'" class="add_digest_btn" @click="toAdd"><i class="iconfont iconjiashang1"></i></div>
      </div>
    </div>
    <template v-if="operation">
      <digest-operation :operation.sync="operation" :item="digestOperationItem" :books="bookList" @refreshDigestList="refreshDigestList" />
    </template>
  </div>
</template>

<script>
// import commonNav from '@/component/commonNav'
import digestListCard from './component/digestListCard.vue'
import digestOperation from './component/digestOperation.vue'
import { allDigest, allBook } from '@/vue_api/digest.js'
export default {
  name: 'digest',
  components: {
    // commonNav,
    digestListCard,
    digestOperation
  },
  data () {
    return {
      digestList: [],
      bookList: [],
      digestOperationItem: {},
      operation: 0,  //  操作开关
      bookType: 0,  //  用于书籍分类查询
      pageNum: 0,  //  用于书籍分页查询
      bookTypes: ['全 部', '文 学 类', '小 说 类', '信 仰 类'],
      bookPage: 1
    }
  },
  mounted () {
    this.getAllDigest()
    this.getAllBook()
  },
  methods: {
    bookDigest (bookName) {
      this.getAllDigest(bookName)
    },
    nextPage () {
      this.pageNum++
      this.getAllBook()
    },
    prevPage () {
      this.pageNum--
      this.getAllBook()
    },
    // 图书分页跳转
    toPage (val) {
      this.pageNum = val - 1
      this.getAllBook()
    },
    // 图书分类查询
    changType (val) {
      this.bookType = val
      this.pageNum = 0
      this.getAllBook()
      if (val == 0) {
        this.getAllDigest()
      }
    },
    // 获取图书信息
    async getAllBook () {
      const params = {
        pageNum: this.pageNum,
        bookType: this.bookType
      }
      await allBook({ ...params }).then(res => {
        console.log('所有图书----', res)
        this.bookList = res && res.data && res.data.status ? res.data.bookList : []
        this.bookPage = res && res.data && res.data.status ? Math.ceil(res.data.total / 9) : 1
      })
    },
    // 获取书摘
    async getAllDigest (val) {
      this.digestList = []
      await allDigest({ bookName: val || '' }).then(res => {
        console.log('所有文摘----', res)
        this.digestList = res && res.data && res.data.status ? res.data.digestList : []
      })
    },
    // 添加书摘
    toAdd () {
      this.digestOperationItem = {}
      this.operation = 1
    },
    // // 弹窗内部点击
    // digestClick(val) {
    //   this.operation = val
    //   // console.log('来自子组件的显示', this.operation)
    // },
    // 添加完书摘后刷新
    refreshDigestList (val) {
      if (val) {
        this.pageNum = 0
        this.bookType = 0
        this.getAllDigest()
        this.getAllBook()
      }
    },
    // 书摘详情
    digestInfo (item) {
      console.log('查看卡片细节', item)
      this.digestOperationItem = item
      this.operation = 1
    }
  }
}
</script>

<style lang="scss" scoped>
@import "index.scss";
</style>