import baseApi from './base_api.js'

//  查询文摘
export function allDigest(options) {
  return baseApi.post('/allDigest', {...options})
}

// 增加文摘
export function addDigest(options) {
  return baseApi.post('/addDigest', {...options})
}

// 查询书籍
export function allBook(options) {
  return baseApi.post('/allBook', {...options})
}