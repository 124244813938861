import baseApi from './base_api.js'

//  上传书本封面
export function uploadImg(options) {
  return baseApi.post('/uploadImg', options, {headers: {'Content-Type': 'multipart/form-data'}})
}

//  删除书本封面
export function deleteImg(options) {
  return baseApi.post('/removeImg', options)
}

//  上传音频
export function uploadRec(options) {
  return baseApi.post('/uploadRec', options, {headers: {'Content-Type': 'multipart/form-data'}})
}

// 上传照片墙-嘿嘿大家照片
export function uploadPublicPhoto(options) {
  return baseApi.post('/uploadPublicPhoto', options, {headers: {'Content-Type': 'multipart/form-data'}})
}

// 删除照片墙-嘿嘿大家照片
export function removePublicPhoto(options) {
  return baseApi.post('/removePublicPhoto', options)
}

// 上传照片墙-哈哈是我照片
export function uploadMyPhoto(options) {
  return baseApi.post('/uploadMyPhoto', options, {headers: {'Content-Type': 'multipart/form-data'}})
}

// 删除照片墙-哈哈是我照片
export function removeMyPhoto(options) {
  return baseApi.post('/removeMyPhoto', options)
}
