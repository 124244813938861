<template>
  <div class="operation_box" @click="closeDigest">
    <div class="add_digest" @click.stop="digestClick">
      <div class="title_box">
        <span class="title">
          <span v-if="!edit">新增</span><span v-else>修改</span>书摘
        </span>
      </div>
      <div class="content_box">
        <div class="content_title">封面：
          <div class="img_logo_box">
            <template class="img_logo_box" v-if="!params.logoUrl">
              <i class="iconfont iconjiashang1"></i>
              <input class="img_logo_input" type="file" @change="imgChange">
            </template>
            <div v-else class="img_logo">
              <img :src="params.logoUrl" :alt="params.bookName">
            </div>
            <div v-if="params.logoUrl" class="delete" @click="deleteImg">
              <i class="iconfont iconshanchu"></i>
            </div>
          </div>
        </div>
        <div class="content_title">作者：
          <div>
            <input class="input" type="text" v-model="params.bookAuthor">
          </div>
        </div>
        <div class="content_title required">书名：
          <div class="book_name_select">
            <input class="input" type="text" v-model="params.bookName" @click.stop="selectBoxShow = 1, selectItemShow = 1" @blur="SelectBoxBlur" @input="bookChange">
            <div class="select_operation" v-show="selectBoxShow || selectItemShow">
              <div class="book_name" v-for="(item, index) in bookList" :Key="index" @click.stop="selectBook(item)">
                {{item.bookName}}
              </div>
            </div>
            <span style="color: rgb(255, 92, 0);" v-show="!params.bookName.trim()"> 书名为必填项</span>
          </div>
        </div>
        <div class="content_title">
          <span class="required">分类：</span>
          <div>
            <select class="input select" v-model="params.bookType" name="" id="" @change="selectChange">
              <option v-for="(item, index) in bookTypes" :key="index" :value="index + 1">
                {{item}}
              </option>
            </select>
          </div>
        </div>
        <div class="content_title">
          <span class="required">标题：</span>
          <div>
            <input class="input title_content" type="text" v-model="params.title">
            <span style="color: rgb(255, 92, 0);" v-show="!params.title.trim()"> 标题为必填项</span>
          </div>
        </div>
        <div class="content_title">内容：
          <div>
            <textarea name="" id="" cols="60" rows="10" v-model="params.content"></textarea>
          </div>
        </div>
      </div>
      <div class="button_box">
        <div v-if="!edit && userName == 'zhouyichang'" class="add" @click="add">添加</div>
        <div v-if="edit && userName == params.editor" class="edit" @click="add">修改</div>
        <div class="cancel" @click.stop="cancel">取消</div>
      </div>
    </div>
  </div>
</template>

<script>
  import _ from 'lodash'
  import {addDigest} from '../../../vue_api/digest.js'
  import {uploadImg, deleteImg} from '../../../vue_api/upload.js'
  export default {
    name: 'digestOperation',
    data() {
      return {
        params: {
          id: null, //  编辑时需要有具体的id
          content: '',
          editor: '',
          editTime: '',
          logoUrl: '',
          bookName: '',
          bookType: 1,
          bookAuthor: '',
          title: '',
          imgName: ''
        },
        bookTypes: ['文学类', '小说类', '信仰类'],
        bookList: [...this.books],
        edit: 0,
        imgStatus: '',
        selectBoxShow: 0,
        selectItemShow: 0
      }
    },
    props: {
      item: {
        type: Object,
        default() {
          return {}
        }
      },
      books: {
        type: Array,
        default() {
          return []
        }
      }
    },
    mounted() {
      this.edit = this.item.id ? 1 : 0
      if(this.edit) {
        this.params = {...this.item}
      } else {
        this.params.editor = this.userName
      }
      
      // console.log('这里有没有---', this.edit, this.item, this.params,sessionStorage.getItem('chatUser'), this.params.editor)
      console.log('这里有没有---', this.params, this.userName)
    },
    methods: {
      bookChange: _.debounce(function(e) {
        this.bookList = []
        this.books.forEach((item) => {
          if(item.bookName.indexOf(e.target.value) != -1) {
            this.bookList.push(item)
          }
          // item.bookName.indexOf(e.target.value) != -1 && this.books.push(item)
        })
        console.log(e.target.value, this.books, this.bookList)
      }, 400),
      // 书名框失焦事件
      SelectBoxBlur() {
        this.selectBoxShow = 0
        setTimeout(() => {
          this.selectItemShow = 0
        }, 200);
      },
      // 选择了书名，带出基本信息
      selectBook(val) {
        Object.assign(this.params, val)
        console.log('params变了没有-----', this.params)
        this.selectItemShow = 0
      },
      selectChange(e) {
        console.log('select的值改变-----', e, this.params.bookType)
      },
      // 删除图片，其实是图片转移
      deleteImg() {
        console.log('删除图片', this.params)
        deleteImg({fileName: this.params.imgName}).then(res => {
          console.log('图片删除了吗？？？？----', res)
          if (res && res.data && res.data.status) {
            this.$nextTick(() =>{
              this.params.logoUrl = ''
              this.params.imgName = ''
              this.imgStatus = '封面删除成功~'
            })
          } else {
            this.$nextTick(() =>{
              this.imgStatus = '封面删除失败啦！'
            })
          }
        })
      },
      //  上传图片
      async imgChange(e) {
        console.log('imgChange=====', e.target.files[0].size)
        if (!e.target.files[0].size) {return}
        const file = e.target.files[0]
        e.preventDefault()
        let formData = new FormData();
        formData.append('file', file)
        console.log('即将上传图片====', formData, file)
        await uploadImg(formData).then(res =>{
          console.log('上传图片完成====', res.data)
          if(res && res.data && res.data.status) {
            this.$nextTick(() =>{
              this.params.logoUrl = res.data.url
              this.params.imgName = res.data.name
              console.log('img相关', this.params.imgName, this.params.logoUrl)
              this.imgStatus = '封面添加成功~'
            })
          } else {
            this.$nextTick(() =>{
              this.imgStatus = '封面添加失败啦！'
            })
          }
        }).catch(err => {
          console.log('上传失败了====', err)
        })
      },
      // 新增书摘
      async add() {
        if(!this.params.title.trim() || !this.params.bookName.trim()) return
        await addDigest({...this.params}).then(res =>{
          console.log('添加成功----', res)
          this.cancel()
          this.$emit('refreshDigestList', 1)
        })
      },
      // 点击遮罩层关闭
      closeDigest() {
        console.log('点击遮罩层关闭');
        this.$emit('update:operation', 0)
      },
      // 不关闭遮罩层
      digestClick() {
        console.log('点击了组件保持开启')
        this.$emit('update:operation', 1)
      },
      // 关闭遮罩层
      cancel() {
        this.$emit('update:operation', 0)
      }
    }
  }
</script>

<style lang="scss" scoped>
 @import 'index.scss'
</style>